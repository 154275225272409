@mixin respond-to($media) {
  @if $media == mobile {
    @media screen and (max-width: 640px) {
      @content
    }
  }
}

@mixin about-header($imageURL, $backgroundX, $backgroundY) {
  @extend .jumbotron;

  background-image: url($imageURL);
  background-position: $backgroundX, $backgroundY;
}