.tag-header {
  @extend .page-header;

  text-align: center;

  a {
    color: green;
  }
}

.tagged-posts {
  @extend .list-group;

  .tagged-post {
    @extend .list-group-item;
  }

  .tag-link {
    @extend .list-group-item;

    text-align: center;
  }
}

.all-tags {
  @extend .list-group;

  .tag {
    @extend .list-group-item;

    text-align: center;

    .active {

    }

  }
}