.blog-title {
  @extend .page-header;

  a {
    color: green;
  }
}

.btn-blog {
  @include button-variant(#ffffff, green, darkgreen)
}

.blog-tags {
  @extend .list-group;
  .blog-tag {
    @extend .list-group-item;
  }
}