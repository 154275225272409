// Import Bootstrap
@import "bootstrap/assets/stylesheets/bootstrap";

@import "partials/mixins";
@import "partials/home";
@import "partials/blog";
@import "partials/about";
@import "partials/gigs";
@import "partials/tags";
@import "partials/songs";

.flash-message {
  @extend .alert;
  @extend .alert-info;
}

.main-text {
  @extend .col-md-12;
}

.sidebar {
  @extend .col-md-4;
}

.center {
    text-align: center;
}